import { template as template_bb59d01df7f2412bb00c680a525debe0 } from "@ember/template-compiler";
const FKLabel = template_bb59d01df7f2412bb00c680a525debe0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
