import { template as template_3be26af09c2043d4baa946a19c1f9eac } from "@ember/template-compiler";
const FKControlMenuContainer = template_3be26af09c2043d4baa946a19c1f9eac(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
