import { template as template_9a58142455914cf7b2cff1f3b9f7f838 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_9a58142455914cf7b2cff1f3b9f7f838(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
