import { template as template_03e6b5f8bc524133a9f3db2baa05c506 } from "@ember/template-compiler";
const WelcomeHeader = template_03e6b5f8bc524133a9f3db2baa05c506(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
