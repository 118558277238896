import { template as template_c8cc9e808b5e4ac8bd0f45a0ad1e051a } from "@ember/template-compiler";
const FKText = template_c8cc9e808b5e4ac8bd0f45a0ad1e051a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
